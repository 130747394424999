import { sortByFn, sortByKey, sortByKeys } from "@notemeal/utils-sort";
import { addSelectionOptionsToMenuItem } from "@notemeal/shared-ui";
import { formatDatetimeInTimezone, formatTimeInTimezone } from "@notemeal/utils-date-time";
import { convertToOrderWithDiningStationItems, getTicketDisplayNameForAthlete } from "./Ticket/utils";
import {
  KdsPageMealMenuDiningStationFragment,
  KdsPageMealMenuPlateOrderPreviewFragment,
  KdsPageMenuOrderPreviewFragment,
  MenuOrderFormFragment,
  MenuOrderTimelineFragment,
  NamedTagForSelectionFragment,
} from "../../types";
import { menuOrderItemIdsToDiningStationName } from "../../utils/menuItems";
import { WebPrinterTicketOrder } from "./types";
import { Locale } from "date-fns";
import { GenericOrderPreview, GenericOrderWithItems } from "./Ticket/types";
import { reduceStatuses, statusToSortOrder } from "@notemeal/shared-ui";
interface hasNewIdProps {
  incomingIds: string[];
  existingIds: string[];
}

export enum KDSOrderDiningOption {
  DineIn = "Dine-in",
  ToGo = "To-go",
  ViewAll = "View All",
  None = "None",
}

export const getDiningOptionLabel = (orderPreview: GenericOrderPreview | MenuOrderFormFragment | MenuOrderTimelineFragment) => {
  if (orderPreview.__typename === "MenuOrder") {
    if (orderPreview.isDineIn) {
      return KDSOrderDiningOption.DineIn;
    }
    if (orderPreview.isToGo) {
      return KDSOrderDiningOption.ToGo;
    }
  }
  return KDSOrderDiningOption.None;
};

export const hasNewId = ({ incomingIds, existingIds }: hasNewIdProps) => {
  return incomingIds.some(ioid => !existingIds.includes(ioid));
};

export const getNewIds = ({ incomingIds, existingIds }: hasNewIdProps) => {
  const existingIdSet = new Set(existingIds);
  return incomingIds.filter(elem => !existingIdSet.has(elem));
};

interface OrderTicketContentProps {
  order: GenericOrderWithItems;
  mealMenuDiningStations: (KdsPageMealMenuDiningStationFragment & {
    mealMenuId: string;
  })[];
  selectedDiningStationIds: string[];
}

interface TicketHeaderProps {
  order: GenericOrderPreview;
  isAutoprinting: boolean;
  locale: Locale;
  athleteTags?: readonly NamedTagForSelectionFragment[];
}

export const createTicketOrderForWebPrinter = (
  orderPreview: GenericOrderPreview,
  _orders: readonly GenericOrderWithItems[],
  menuOrderItemIdsToDiningStationName: Record<string, string>
): WebPrinterTicketOrder => {
  const order = _orders.find(o => o.id === orderPreview.id && o.__typename === orderPreview.__typename);
  if (order) {
    return convertToOrderWithDiningStationItems({
      order,
      menuOrderItemIdsToDiningStationName,
    });
  } else {
    alert(`Failed to find order: ${orderPreview.id}`);
    throw new Error(`Failed to find order: ${orderPreview.id}`);
  }
};

// Previously updatedAt was used only if autoprinting was enabled, to simplify and prevent printed tickets
// from being cooked twice creating waste we're now going to show the updatedAt line any time the order has changed.
export const getMenuOrderUpdatedAt = (order: GenericOrderPreview): string | null => {
  // order.updatedAt is updated if the pickup time or any items in the order are changed via order.updateCount
  return order.updatedAt === order.createdAt ? null : order.updatedAt;
};

// Generate an XML string adhering to the
// OpenBravo WebPrinter schema (see KDS/WebPrinter/index.ts for details), which
// is eventually converted to ESC/POS hexadecimal commands to print content via WebUSB.
const getXMLForTicket = (content: string) => {
  return `<?xml version="1.0" encoding="UTF-8"?>
    <output>
      <ticket>
        ${content}
      </ticket>
    </output>`;
};

export const getXMLForOrderTicket = ({
  order,
  mealMenuDiningStations,
  selectedDiningStationIds,
  isAutoprinting,
  locale,
}: OrderTicketContentProps & TicketHeaderProps) => {
  const header = getXMLForOrderTicketHeader({
    order,
    isAutoprinting: isAutoprinting,
    locale,
    athleteTags: order.athlete?.printableTagsForAthlete,
  });
  const content = getXMLForOrderTicketContent({ order, mealMenuDiningStations, selectedDiningStationIds });
  const footer = order.__typename === "MealMenuPlateOrder" ? getXMLForOrderTicketFooter() : "";

  return getXMLForTicket(`${header}${content}${footer}`);
};

export const getXMLForCancelledOrderTicket = (
  order: KdsPageMenuOrderPreviewFragment | KdsPageMealMenuPlateOrderPreviewFragment,
  locale: Locale
) => {
  const header = getXMLForOrderTicketHeader({ order, isAutoprinting: true, locale });
  return getXMLForTicket(`${header} <line></line> <line><text>Order item(s) were cancelled!</text></line>`);
};

const getXMLForOrderTicketHeader = ({ order, isAutoprinting: isAutoPrinting, locale, athleteTags }: TicketHeaderProps) => {
  const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const pickupTime = order.__typename === "MenuOrder" ? order.pickupTime : order.parentBulkOrder.pickupTime;
  // for bulk orders, display same order code for all orders in the bulk order
  const orderCode = order.__typename === "MenuOrder" ? order.code : order.parentBulkOrder.code;

  const codeAndDateTime = `#${orderCode} - ${formatDatetimeInTimezone(pickupTime, clientTimezone, locale)}
  ${" "}${Object.values(order.itemsByDiningStation).length}/${order.itemCount}`;

  let athleteName = order.athlete ? getTicketDisplayNameForAthlete(order.athlete) : order.userFullName;

  const diningOptionLabel = getDiningOptionLabel(order);

  if (diningOptionLabel !== KDSOrderDiningOption.None) {
    athleteName += ` (${diningOptionLabel})`;
  }

  // Bulk order header has team name large at top; subheader has athlete name
  // Menu order header has athlete name large at top; subheader has team name and position
  const bigHeader = order.__typename === "MenuOrder" ? athleteName : order.parentBulkOrder.team.name;
  const subHeader =
    order.__typename === "MenuOrder"
      ? order.athlete
        ? order.athlete.team.name + " " + (order.athlete.position ? `(${order.athlete.position.name || ""})` : "")
        : ""
      : athleteName;

  const menuOrderUpdatedAt = getMenuOrderUpdatedAt(order);

  const updatedAtLine = menuOrderUpdatedAt
    ? `<line><text>Updated At: ${formatTimeInTimezone(menuOrderUpdatedAt, clientTimezone, {
        excludeTimezoneSuffix: true,
      })}</text></line>`
    : null;

  const ticketHeader = `<line size="1"><text>${bigHeader}</text></line>
  <line><text>${subHeader}</text></line>
  ${athleteTags ? `<line><text>Tags: ${athleteTags.map(t => t.tagName.name).join(", ")}</text></line>` : ""}
  <line><text>${codeAndDateTime}</text></line>`;

  return `${ticketHeader}
  ${updatedAtLine}`;
};

const getXMLForOrderTicketContent = ({ order, mealMenuDiningStations, selectedDiningStationIds }: OrderTicketContentProps) => {
  const allergies = `<line><text>${(order.athlete?.dislikedFoodGroups || [])
    .concat(order.athlete?.dislikedFoods || [])
    .map(f => `(!)${f.name}`)
    .join(", ")}</text></line>`;
  const menuOrderWithItems = convertToOrderWithDiningStationItems({
    order,
    menuOrderItemIdsToDiningStationName: menuOrderItemIdsToDiningStationName(
      mealMenuDiningStations.filter(mmds => selectedDiningStationIds.includes(mmds.id)),
      [order]
    ),
  });
  const diningStationNames = Object.keys(menuOrderWithItems.diningStationsToMenuOrderItems).sort();

  const stations = diningStationNames.map(dsName => {
    const stationName = `<line><text bold="true">${dsName}</text></line>`;
    const menuItems = sortByFn(menuOrderWithItems.diningStationsToMenuOrderItems[dsName], item => item.menuItem.name).map(
      ({ amount, menuItem, options, specialRequests, updateCount }) => {
        const servingAmount = `<line><text>  ${updateCount > 0 ? `UPDATE:${updateCount} => ` : ""}${amount} x ${menuItem.name} (${
          menuItem.servingName
        })</text></line>`;
        const addOns = sortByKey(addSelectionOptionsToMenuItem(menuItem, options).choices, "position").map(
          c =>
            c.options.filter(o => !!o.menuSelectionItemOption).length > 0 &&
            `<line><text>    </text><text underline="true">${c.name}</text></line>` +
              sortByKey(c.options, "position").map(
                o => o.menuSelectionItemOption && `<line><text>      -${o.menuSelectionItemOption.amount} x ${o.name}</text></line>`
              )
        );
        const specialRequestsString = specialRequests && `<line><text>Special Requests: ${specialRequests}</text></line>`;

        return servingAmount + addOns.join("\n") + specialRequestsString + `<line></line>`;
      }
    );
    return stationName + (menuItems || []).join("\n");
  });

  return `${allergies}<line></line>${stations}`;
};

const getXMLForOrderTicketFooter = () => {
  return `<line></line><line><text>Powered by Teamworks Nutrition</text></line>`;
};

export const getFormattedOrderKeyWithTicketHeader = <
  Order extends KdsPageMenuOrderPreviewFragment | KdsPageMealMenuPlateOrderPreviewFragment
>(
  order: Order
): { key: string; order: Order } => {
  const orderItemKeys = sortByKey(order.itemsByDiningStation, "id")
    .map(moi => `${moi.id}.${moi.updateCount}`)
    .join(":");
  return { key: `${order.id}|${orderItemKeys}`, order };
};

export const parseFormattedOrderKeyWithTicketHeader = <
  Order extends KdsPageMenuOrderPreviewFragment | KdsPageMealMenuPlateOrderPreviewFragment
>({
  key,
  order,
}: OrderWithKey<Order>): { orderId: string; hasItemsInContext: boolean; order: Order } => {
  return {
    orderId: key.split("|")[0],
    hasItemsInContext: key.split("|")[1] ? key.split("|")[1].length > 0 : false,
    order,
  };
};
export const parseFormattedOrderKey = (key: string): { orderId: string } => {
  return {
    orderId: key.split("|")[0],
  };
};

export interface OrderWithKey<Order extends KdsPageMenuOrderPreviewFragment | KdsPageMealMenuPlateOrderPreviewFragment> {
  key: string;
  order: Order;
}

interface getRemovedOrdersProps<Order extends KdsPageMenuOrderPreviewFragment | KdsPageMealMenuPlateOrderPreviewFragment> {
  incomingOrders: OrderWithKey<Order>[];
  existingOrders: OrderWithKey<Order>[];
}

// TODO: make sure this works for bulk orders
//Cancelled order here is in the KDS context, so this could be either a cancelled order,
// or an edited order that previously had items in context but now does not have items in context
export const getCancelledOrderTickets = <Order extends KdsPageMenuOrderPreviewFragment | KdsPageMealMenuPlateOrderPreviewFragment>(
  { incomingOrders, existingOrders }: getRemovedOrdersProps<Order>,
  locale: Locale
): string[] => {
  const parsedIncomingKeys = incomingOrders.map(parseFormattedOrderKeyWithTicketHeader);
  const parsedExistingKeys = existingOrders.map(parseFormattedOrderKeyWithTicketHeader);
  const existingOrderKeysInContext = parsedExistingKeys.filter(pk => pk.hasItemsInContext);
  const existingMenuOrderIdsInContext = existingOrderKeysInContext.map(pk => pk.orderId);
  const incomingMenuOrderIdsOutOfContext = parsedIncomingKeys.filter(pk => !pk.hasItemsInContext).map(pk => pk.orderId);
  const allIncomingMenuOrderIds = parsedIncomingKeys.map(k => k.orderId);

  const cancelledOrdersInContext = getNewIds({ existingIds: allIncomingMenuOrderIds, incomingIds: existingMenuOrderIdsInContext });
  // edit case that is treated the same as cancel
  const edittedOrdersNoLongerInContext = existingMenuOrderIdsInContext.filter(oKey => incomingMenuOrderIdsOutOfContext.includes(oKey));

  const cancelledOrderTickets = [...cancelledOrdersInContext, ...edittedOrdersNoLongerInContext].flatMap(o => {
    const match = parsedExistingKeys.filter(k => k.orderId === o);
    if (match) {
      return getXMLForCancelledOrderTicket(match[0].order, locale);
    } else {
      return [];
    }
  });
  return cancelledOrderTickets;
};

export const sortMenuOrders = <Order extends GenericOrderPreview>(orders: readonly Order[]): readonly Order[] => {
  const sortedOrders = sortByKeys(
    orders
      .filter(mo => mo.itemsByDiningStation.length > 0)
      // ^ Don't show empty tickets! Otherwise they will appear to be 'cancelled'
      .map(o => ({
        ...o,
        sort: statusToSortOrder(reduceStatuses(o.itemsByDiningStation.map(o => o.status))),
        createdAtInv: new Date(o.createdAt).getTime(),
        // ^ Whoever created their order first should win if pickupTime is tied!
        pickupTime: o.__typename === "MenuOrder" ? o.pickupTime : o.parentBulkOrder.pickupTime,
      })),
    ["pickupTime", "createdAtInv"]
  );

  return sortedOrders;
};
